export class EstimateTimeDetails {
  date: string | Date
  delta: number
  delta_prev: number
  late: boolean
  advance: boolean

  constructor() {
    this.date = null
    this.delta = 0
    this.delta_prev = 0
    this.late = true
    this.advance = true
  }
}
