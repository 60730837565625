import { LoggerService, SharedUserService, UserDefaultDateFormat } from '@agroone-front/shared'
import { User, UserDto, UserPermissions } from '@agroone/entities'
import { environment } from '@agropilot/env/environment'
import { registerLocaleData } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import localeDe from '@angular/common/locales/de'
import localeEs from '@angular/common/locales/es'
import localeFr from '@angular/common/locales/fr'
import localeFrCa from '@angular/common/locales/fr-CA'
import localeHu from '@angular/common/locales/hu'
import localeIt from '@angular/common/locales/it'
import localePl from '@angular/common/locales/pl'
import localePt from '@angular/common/locales/pt'
import localeRu from '@angular/common/locales/ru'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class UserService extends SharedUserService {
  override userApiUrl: string = `${environment.newApiUrl}/users`

  private readonly localLanguages: any[] = [
    localeEs,
    localeFr,
    localeDe,
    localeIt,
    localePt,
    localeRu,
    localePl,
    localeHu,
    localeFrCa,
  ]

  constructor(
    protected override readonly http: HttpClient,
    protected override readonly logger: LoggerService,
    private translate: TranslateService
  ) {
    super(http, logger)
  }

  public override init(): Observable<UserDto> {
    this.user.subscribe((user) => {
      if (user) {
        this.currentUserDateFormat = new UserDefaultDateFormat(user.defaultDateFormat)
        this.currentUserDateHourFormat = new UserDefaultDateFormat((user.defaultDateFormat + ' HH:mm') as any)
        this.localLanguages.forEach((language) => registerLocaleData(language))
        this._setLanguage(user.language)
      }
    })
    return this.getCurrentUser()
  }

  private _setLanguage(language: string) {
    this.translate.setDefaultLang(language)
    this.translate.use(language)
  }

  public hasPermission(user: User, permission: UserPermissions) {
    return user.permissions.includes(permission)
  }
}
