import { addDays, format, subDays } from '@agropilot/app/core/library/date'

export const constants = {
  trucks: {
    chart: {
      width: 5000,
      height: 760,
    },
  },
  paginate: {
    users: 10,
  },
  stocks: {
    chart: {
      width: 6000,
      height: 760,
    },
  },
  planification: {
    timelinePixel: 480,
    dateBeginSector: format(subDays(new Date(), 10), 'yyyy-MM-dd'),
    dateBegin: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
    dateEnd: format(addDays(new Date(), 11), 'yyyy-MM-dd'),
  },
  pc_radio: {
    day_display: 10,
    timelinePixel: 1440,
    date_begin_timeline: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
  },
  truck: {
    thresholds_occupation_time: 60,
    size_column_trucks_name: 107,
    day_display: 3,
    timelinePixel: 1440,
    date_begin_timeline: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
  },
  /**
   * @deprecated Use DateFormatV2 instead
   * TODO: Remove this in the future and use only shared date format between all the apps
   */
  dateFormat: {
    time: "yyyy-MM-dd'T'HH:mm",
    save: 'yyyy-MM-dd HH:mm',
    view: 'dd/MM/yyyy HH:mm',
    onlyDate: 'yyyy-MM-dd',
    full: 'yyyy-MM-dd HH:mm:ss',
    hourMinute: 'HH:mm',
  },
  regex: {
    onlyDate: '[0-9]{4}-([0][0-9]|[1][0-2])-([0][0-9]|[1][0-9]|[2][0-9]|[3][0-1])', // yyyy-MM-dd
  },
}

export const settings = {
  min_lot_time: '15',
  defaultTimes: {
    distance: 70,
    truckSpeed: 60,
    travel_time_offer: 30,
    travel_time_site: 30,
    time_dump_truck: 90,
  },
  defaultColors: {
    r: '#67C6E8',
    v: '#009245',
    ec: '#EF7159',
    es: '#BB7EE8',
    ext: '#AEAEAE',
    default: '#EFD58D',
  },
}
