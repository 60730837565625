import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Croptype } from '@agroone/entities'
import { CroptypeNetworkService } from './croptype.network.service'

@Injectable({
  providedIn: 'root',
})
export class CroptypeService {
  public croptypes: Observable<Croptype[]>

  private croptypesSubject: BehaviorSubject<Croptype[]> = new BehaviorSubject<Croptype[]>(null)
  get currentCroptypes(): Croptype[] {
    return this.croptypesSubject.getValue()
  }

  constructor(private croptypeNetworkService: CroptypeNetworkService) {
    this.croptypes = this.croptypesSubject.asObservable()
  }

  init(): Observable<Croptype | Croptype[]> {
    return this.getCroptypes()
  }

  getCroptypes(): Observable<Croptype | Croptype[]> {
    return this.croptypeNetworkService.get().pipe(
      map((croptypes: Croptype[]) => {
        if (croptypes) {
          this.croptypesSubject.next(croptypes)
        }
        return croptypes
      })
    )
  }
}
