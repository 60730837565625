import { BaseModel } from '@agroone/entities'
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { BehaviorSubject, firstValueFrom, fromEvent, merge, Observable, of, Subject } from 'rxjs'
import { map, mapTo } from 'rxjs/operators'

import { ErrorEnum } from '../../error/models/error.enum'
import { Error } from '../../error/models/error.model'
import { ErrorService } from '../../error/services/error.service'

/**
 * Class responsible to provide the online status of the application
 *
 * @export
 * @class NetworkingService
 * @implements {OnDestroy}
 */
@Injectable()
export class NetworkingService {
  /** @ignore */
  // eslint-disable-next-line @typescript-eslint/typedef
  private readonly TAG = 'NetworkingService'

  /** @ignore */
  private connectionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  /**
   * Get an Observable of the application network status.<br/>
   * <b>true</b> => online <br/>
   * <b>false</b> => offline
   *
   * @readonly
   * @type {Observable<boolean>}
   * @memberof NetworkingService
   */
  public get connection(): Observable<boolean> {
    return this.connectionSubject.asObservable()
  }

  /**
   * Get the current status of the network.<br/>
   * <b>true</b> => online <br/>
   * <b>false</b> => offline
   *
   * @readonly
   * @type {boolean}
   * @memberof NetworkingService
   */
  public get connected(): boolean {
    return this.connectionSubject.getValue()
  }

  /** @ignore */
  constructor(private http: HttpClient, private errorService: ErrorService) {
    merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    ).subscribe((cConnected: boolean) => {
      this.connectionSubject.next(cConnected)
    })
  }

  /**
   * Use this class to make an API request.
   * Returns class extending {@link BaseModel} or throws an error
   * if an error occured.
   *
   * @template T
   * @param classType Class that extends BaseModel
   * @param url
   * @param [options]
   * @returns
   * @memberof NetworkingService
   */
  get<T extends BaseModel>(url: string, options?: { headers?: HttpHeaders; params?: HttpParams }): Observable<T | T[]> {
    const subject: Subject<T> = new Subject()

    if (!this.connected) {
      const tError: Error = this.errorService.getErrorForLocaleCode(ErrorEnum.NO_NETWORK)
      subject.error(tError)
    } else {
      this.http
        .get(url, options)
        .pipe(
          map((response: HttpResponse<any>) => {
            try {
              if ((response as any).data && (response as any).currentPage) {
                return (response as any).data
              }
              return response
            } catch (error) {
              const tError: Error = this.errorService.getErrorForLocaleCode(ErrorEnum.MALFORMED_JSON)
              throw tError
            }
          })
        )
        .subscribe(
          (value: any) => {
            subject.next(value)
            subject.complete()
          },
          (error: Error) => {
            if (error instanceof HttpResponse) {
              switch (error.mCode) {
                case 404:
                  error = this.errorService.getErrorForLocaleCode(ErrorEnum.NOT_FOUND)
                  break
                case 500:
                  error = this.errorService.getErrorForLocaleCode(ErrorEnum.INTERNAL_ERROR)
                  break
                default:
                  error = this.errorService.getErrorForLocaleCode(ErrorEnum.UNDEFINED)
                  break
              }
            }
            subject.error(error)
          }
        )
    }

    return subject.asObservable()
  }
  /**
   * Put http request
   *
   * @param url
   * @param body
   * @returns
   * @memberof NetworkingService
   */
  put(url: string, body: any) {
    return firstValueFrom(
      this.http.put(url, body, { observe: 'response' }).pipe(
        map((response: HttpResponse<any>) => {
          try {
            return response
          } catch (error) {
            const tError: Error = this.errorService.getErrorForLocaleCode(ErrorEnum.MALFORMED_JSON)
            throw tError
          }
        })
      )
    )
  }
  /**
   * Post http request
   *
   * @param url
   * @param body
   * @returns
   * @memberof NetworkingService
   */
  post(url: string, body: any) {
    return firstValueFrom(
      this.http.post(url, body, { observe: 'response' }).pipe(
        map((response: HttpResponse<any>) => {
          try {
            return response
          } catch (error) {
            const tError: Error = this.errorService.getErrorForLocaleCode(ErrorEnum.MALFORMED_JSON)
            throw tError
          }
        })
      )
    )
  }
}
