/**
 * Defines an Error object. <br/>
 * <p> An Error has in all cases a code and a message. <p/>
 * <p> An Error can be a failure meaning something went wrong.
 * If it isn't the case then it is by default an informative message. </p>
 * <p> An Error may be retryable.
 *  If it is then it must have a retry action which is a function </p>
 *
 * @export
 * @class Error
 */
export class Error {
  /**
   * Code defined in @link ErrorEnum
   *
   * @type {number}
   * @memberof Error
   */
  mCode: number

  /**
   * The informative message
   *
   * @type {string}
   * @memberof Error
   */
  mMessage: string

  /**
   * Error can be an error or it can be a pure informative message
   *
   * @type {boolean}
   * @memberof Error
   */
  mIsFailure: boolean

  /**
   * Whether the Error should permit to retry the failed action
   *
   * @type {boolean}
   * @memberof Error
   */
  mShouldRetryOnFailure: boolean

  /**
   * The action (function) to replay
   *
   * @memberof Error
   */
  mRetryAction: () => void

  /**
   * The action (function) to call on 'OK' action (default action)
   *
   * @memberof Error
   */
  mOkAction: () => void

  /**
   * Creates an instance of Error.
   *
   * @param sCode
   * @param sMessage
   * @param [sIsFailure=false]
   * @param [sShouldRetryOnFailure=false]
   * @memberof Error
   */
  constructor(sCode: number, sMessage: string, sIsFailure: boolean = false, sShouldRetryOnFailure: boolean = false) {
    this.mCode = sCode
    this.mMessage = sMessage
    this.mIsFailure = sIsFailure
    this.mShouldRetryOnFailure = sShouldRetryOnFailure
  }

  /**
   * Describes an Error
   *
   * @readonly
   * @type {string}
   * @memberof Error
   */
  get description(): string {
    const tMessage = '\n\t message: ' + this.mMessage ? this.mMessage : 'n/a'
    return '- code: ' + this.mCode + tMessage
  }
}
