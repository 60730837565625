import { Offer } from './offer'

export class Planification {
  id: number
  dateBegin: string
  dateEnd: string
  createdDate: string
  createdBy: string
  cadenceTh: number
  vitesse: number
  initTonnage: number
  quality: string
  modifiedBy: string
  lastUpdate: string
  startupTime: number
  stockId: number
  harvestTeamId: number
  offerId: number
  offer?: Offer

  constructor(planification?: Planification | PlanificationDAO) {
    this.id = planification?.id ?? null
    this.dateBegin = planification?.dateBegin ?? null
    this.dateEnd = planification?.dateEnd ?? null
    this.createdDate = planification?.createdDate ?? null
    this.createdBy = planification?.createdBy ?? null
    this.cadenceTh = planification?.cadenceTh ?? null
    this.vitesse = planification?.vitesse ?? null
    this.initTonnage = planification?.initTonnage ?? null
    this.quality = planification?.quality ?? null
    this.modifiedBy = planification?.modifiedBy ?? null
    this.startupTime = planification?.startupTime ?? null
    this.stockId = planification?.stockId ?? null
    this.harvestTeamId = planification?.harvestTeamId ?? null
    this.offerId = planification?.offerId ?? null
    this.offer = planification?.offer ?? null
    if (planification?.lastUpdate && typeof planification?.lastUpdate === 'object') {
      this.lastUpdate = planification.lastUpdate.toISOString()
    } else {
      this.lastUpdate = (planification?.lastUpdate as string) ?? null
    }
  }
}

export class SavePlanification {
  id: number
  dateBegin: string
  dateEnd: string
  createdDate: string
  createdBy: string
  cadenceTh: number
  vitesse: number
  initTonnage: number
  quality: string
  modifiedBy: string
  lastUpdate: string
  startupTime: number
  stockId: number
  harvestTeamId: number
  offerId: number

  constructor(savePlanification?: SavePlanification) {
    if (savePlanification?.id) {
      this.id = savePlanification?.id
    }
    this.dateBegin = savePlanification?.dateBegin
    this.dateEnd = savePlanification?.dateEnd
    this.createdDate = savePlanification?.createdDate
    this.createdBy = savePlanification?.createdBy
    this.cadenceTh = savePlanification?.cadenceTh
    this.vitesse = savePlanification?.vitesse
    this.initTonnage = savePlanification?.initTonnage
    this.quality = savePlanification?.quality
    this.modifiedBy = savePlanification?.modifiedBy
    this.lastUpdate = savePlanification?.lastUpdate
    this.startupTime = savePlanification?.startupTime
    this.stockId = savePlanification?.stockId
    this.harvestTeamId = savePlanification?.harvestTeamId
    this.offerId = savePlanification?.offerId
  }
}

export class PlanificationDAO {
  id: number
  dateBegin: string
  dateEnd: string
  createdDate: string
  createdBy: string
  cadenceTh: number
  vitesse: number
  initTonnage: number
  quality: string
  modifiedBy: string
  lastUpdate: Date
  startupTime: number
  stockId: number
  harvestTeamId: number
  offerId: number
  offer?: Offer
}
