import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ErrorEnum } from '../models/error.enum'
import { Error } from '../models/error.model'

@Injectable()
export class ErrorService {
  constructor(private translate: TranslateService) {}

  public getErrorForLocaleCode(sCode: ErrorEnum): Error {
    switch (sCode) {
      case ErrorEnum.NO_NETWORK:
        return new Error(sCode.valueOf(), this.translate.instant('ERROR.NO_NETWORK'), true, true)
      case ErrorEnum.NOT_FOUND:
        return new Error(sCode.valueOf(), this.translate.instant('ERROR.NOT_FOUND'), true, false)
      case ErrorEnum.INTERNAL_ERROR:
        return new Error(sCode.valueOf(), this.translate.instant('ERROR.INTERNAL'), true, false)
      case ErrorEnum.MALFORMED_JSON:
        return new Error(sCode.valueOf(), this.translate.instant('ERROR.MALFORMED_JSON'), true, true)
      default:
        return new Error(sCode.valueOf(), this.translate.instant('ERROR.UNDEFINED'), true)
    }
  }
}
