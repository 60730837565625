import * as date_fns from 'date-fns'

/**
 * @deprecated Use formatDateV2 or formatDateForUser instead
 * @param date
 * @param dateFormat
 */
export const format = (date: string | number | Date, dateFormat?: string): string => {
  if (typeof date === 'string') {
    date = date_fns.parseISO(date)
  }

  return date_fns.format(date, dateFormat)
}

const convertToDate = (date: string | number | Date): Date => {
  if (typeof date === 'string') {
    return date_fns.parseISO(date)
  }

  return date_fns.toDate(date)
}

export const parse = (date: string | Date): Date => convertToDate(date)

export const addDays = (date: string | number | Date, nbDays: number): Date =>
  date_fns.addDays(convertToDate(date), nbDays)

export const subDays = (date: string | number | Date, nbDays: number) => date_fns.subDays(convertToDate(date), nbDays)

export const addHours = (date: string | number | Date, nbHours: number) =>
  date_fns.addHours(convertToDate(date), nbHours)

export const subHours = (date: string | number | Date, nbHours: number) =>
  date_fns.subHours(convertToDate(date), nbHours)

export const addMinutes = (date: string | number | Date, nbMinutes: number) =>
  date_fns.addMinutes(convertToDate(date), nbMinutes)

export const subMinutes = (date: string | number | Date, nbMinutes: number) =>
  date_fns.subMinutes(convertToDate(date), nbMinutes)

export const addSeconds = (date: string | number | Date, nbSeconds: number) =>
  date_fns.addSeconds(convertToDate(date), nbSeconds)

export const subSeconds = (date: string | number | Date, nbSeconds: number) =>
  date_fns.subSeconds(convertToDate(date), nbSeconds)

export const isAfter = (date: string | number | Date, dateToCompare: string | number | Date): boolean =>
  date_fns.isAfter(convertToDate(date), convertToDate(dateToCompare))

export const isBefore = (date: string | number | Date, dateToCompare: string | number | Date): boolean =>
  date_fns.isBefore(convertToDate(date), convertToDate(dateToCompare))

export const isWithinInterval = (
  date: string | number | Date,
  startDate: string | number | Date,
  endDate: string | number | Date
): boolean =>
  date_fns.isWithinInterval(convertToDate(date), {
    start: convertToDate(startDate),
    end: convertToDate(endDate),
  })

export const isEqual = (date: string | number | Date, dateToCompare: string | number | Date): boolean =>
  date_fns.isEqual(convertToDate(date), convertToDate(dateToCompare))

export const differenceInDays = (dateLeft: string | number | Date, dateRight: string | number | Date): number =>
  date_fns.differenceInDays(convertToDate(dateLeft), convertToDate(dateRight))

export const differenceInCalendarDays = (dateLeft: string | number | Date, dateRight: string | number | Date): number =>
  date_fns.differenceInCalendarDays(convertToDate(dateLeft), convertToDate(dateRight))

export const differenceInMinutes = (dateLeft: string | number | Date, dateRight: string | number | Date): number =>
  date_fns.differenceInMinutes(convertToDate(dateLeft), convertToDate(dateRight))

export const differenceInSeconds = (dateLeft: string | number | Date, dateRight: string | number | Date): number =>
  date_fns.differenceInSeconds(convertToDate(dateLeft), convertToDate(dateRight))

export const setHours = (date: string | number | Date, hours: number): Date =>
  date_fns.setHours(convertToDate(date), hours)

export const setMinutes = (date: string | number | Date, minutes: number): Date =>
  date_fns.setMinutes(convertToDate(date), minutes)

export const setSeconds = (date: string | number | Date, seconds: number): Date =>
  date_fns.setSeconds(convertToDate(date), seconds)

export const setMilliseconds = (date: string | number | Date, seconds: number): Date =>
  date_fns.setMilliseconds(convertToDate(date), seconds)

export const isValid = (date: Date): boolean => date_fns.isValid(date)

export const getDate = (date: string | number | Date): number => date_fns.getDate(convertToDate(date))

export const getHours = (date: string | number | Date): number => date_fns.getHours(convertToDate(date))

export const getMinutes = (date: string | number | Date): number => date_fns.getMinutes(convertToDate(date))

export const getSeconds = (date: string | number | Date): number => date_fns.getSeconds(convertToDate(date))

export const getTime = (date: string | number | Date): number => date_fns.getTime(convertToDate(date))

export const isSameDay = (first: Date, second: Date): boolean => date_fns.isSameDay(first, second)

export const startOfDay = (date: string | number | Date): Date => date_fns.startOfDay(convertToDate(date))
