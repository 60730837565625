<div [class.active]="active$ | async" class="backdrop"></div>
<div [class.active]="active$ | async" class="loading-screen">
  <div class="loading-content">
    <i class="pi pi-spinner" [class.animated]="active$ | async"></i>
    <ng-container *ngIf="message$ | async as message">
      <p [class.animated]="(animated$ | async) && (active$ | async)">
        {{ message }}
      </p>
    </ng-container>
  </div>
</div>
