import { Lot } from '@agroone/entities'
import { constants } from '@agropilot/env/constants'
import { format, addDays, parse } from '@agropilot/app/core/library/date'
import { dateToISO8601 } from '../helpers/date'
import { Planification } from '@agroone/entities'

export const generateMultipleDates = (dateBegin: Date, nbDays: number): Date[] => {
  const dates = []
  dates.push(format(dateBegin, constants.dateFormat.onlyDate))
  for (let i = 0, total = nbDays - 1; i < total; i++) {
    const currentDate = format(dateBegin, constants.dateFormat.onlyDate)
    const newDate = addDays(currentDate, i + 1)
    dates.push(format(newDate, constants.dateFormat.onlyDate))
  }
  return dates
}

export const convertMinutesToHours = (minutes: number): string => {
  const nbHours = Math.trunc(minutes / 60)
  const resteMinute = Math.trunc(minutes - nbHours * 60)

  if (nbHours > 0) {
    const reste = resteMinute < 10 ? '0' + resteMinute : resteMinute
    return `${nbHours}h${reste}'`
  }
  return `${resteMinute}'`
}

export const formatDateLot = (lots: Lot[]): Lot[] =>
  lots.map((lot: Lot) => {
    ;[
      'dateBegin',
      'dateEnd',
      'dateBeginBreak',
      'dateEndBreak',
      'estimationStartWork',
      'startWork',
      'estimationCheckInSite',
      'checkInSite',
      'estimationCheckOutSite',
      'checkOutSite',
      'estimationCheckInFactory',
      'checkInFactory',
      'estimationCheckOutFactory',
      'checkOutFactory',
    ].forEach((k) => {
      if (lot[k]) {
        lot[k] = dateToISO8601(parse(lot[k]))
      }
    })
    return lot
  })

export const formatDatePlanification = (planifications: Planification[]): Planification[] =>
  planifications.map((planification: Planification) => {
    ;['dateBegin', 'dateEnd'].forEach((k) => {
      if (planification[k]) {
        planification[k] = dateToISO8601(parse(planification[k]))
      }
    })
    return planification
  })

export const parseDateFilter = (date: Date): string => {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  month = month.padStart(2, '0')
  day = day.padStart(2, '0')

  return [year, month, day].join('-')
}

export const weekNumber = (d: Date): number => {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
  const yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
  const weekNo = Math.ceil((((d as any) - yearStart) / 86400000 + 1) / 7)
  return weekNo
}

export const getFirstDayOfWeek = (d: Date): Date => {
  const date = new Date(d)
  const day = date.getDay()
  const diff = date.getDate() - day + (day === 0 ? -6 : 1)
  return new Date(date.setDate(diff))
}
