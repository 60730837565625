export const dateToISO8601 = (value: Date = new Date()): string => {
  if (!value || !(value instanceof Date)) {
    throw new Error(`dateToISO8601: Expected a date, instead got ${value}`)
  }
  const tzo = -value.getTimezoneOffset()
  const dif = tzo >= 0 ? '+' : '-'
  const parsedValue =
    value.getFullYear() +
    '-' +
    pad(value.getMonth() + 1) +
    '-' +
    pad(value.getDate()) +
    'T' +
    pad(value.getHours()) +
    ':' +
    pad(value.getMinutes()) +
    ':' +
    pad(value.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  return parsedValue
}

export const ISO8601ToDate = (value: string): Date => {
  /* eslint-disable max-len */
  const regex =
    /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/
  /* eslint-enable max-len */
  try {
    // Check format of date string
    if (!/^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d)$/.test(value) && !checkIsISO8601(value)) {
      return undefined
    }
    return value ? new Date(Date.parse(value)) : undefined
  } catch (err) {
    return undefined
  }
}

export const checkIsISO8601 = (value: string): boolean => {
  /* eslint-disable max-len */
  const regex =
    /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/
  /* eslint-enable max-len */
  try {
    // Check format of date string
    if (regex.test(value)) {
      return true
    }
    return false
  } catch (err) {
    return false
  }
}

const pad = (num: number): string => {
  const norm = Math.floor(Math.abs(num))
  return (norm < 10 ? '0' : '') + norm
}
