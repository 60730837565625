import { Component } from '@angular/core'
import { LoadingService } from '@agropilot/app/core/services/loading/loading.service'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.sass'],
})
export class LoadingScreenComponent {
  constructor(private loadingService: LoadingService) {}

  get active$(): Observable<boolean> {
    return this.loadingService.status$
  }

  get message$(): Observable<string> {
    return this.loadingService.message$
  }

  get animated$(): Observable<boolean> {
    return this.loadingService.animated$
  }
}
