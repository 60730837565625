export class FactoryRealTime {
  plotCode: string
  job: string
  count: string
  species: string
  exploitation: string
  site: string
  factory: string
  quantity: number
  quality: string
  startWork: string
  checkInSite: string
  checkOutSite: string
  checkInFactory: string
  checkOutFactory: string

  constructor() {
    this.plotCode = ''
    this.job = ''
    this.count = ''
    this.species = ''
    this.exploitation = ''
    this.site = ''
    this.factory = ''
    this.quantity = 0
    this.quality = ''
    this.startWork = ''
    this.checkInSite = ''
    this.checkOutSite = ''
    this.checkInFactory = ''
    this.checkOutFactory = ''
  }
}

export class FactoryRealTimeByLot {
  offer_id: number
  lot_id: number
  factory_realtime: FactoryRealTime

  constructor(factoryRealtimeByLot: FactoryRealTimeByLot) {
    this.offer_id = factoryRealtimeByLot?.offer_id ?? null
    this.lot_id = factoryRealtimeByLot?.lot_id ?? null
    this.factory_realtime = factoryRealtimeByLot?.factory_realtime ?? null
  }
}
