import { NgModule, ModuleWithProviders } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ErrorService } from './services/error.service'

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [],
})
export class ErrorModule {
  static forRoot(): ModuleWithProviders<ErrorModule> {
    return {
      ngModule: ErrorModule,
      providers: [ErrorService],
    }
  }
}
