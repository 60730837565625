import { environment as environmentPrd } from './environment.prd'

export const environment = {
  ...environmentPrd,
  production: false,
  qlf: true,
  dynatraceScriptSrc: '',
  newApiUrl: 'https://apiv2.cldqlf.bonduelle.com',
  hubUrl: 'https://agroone.cldqlf.bonduelle.com',
  websocket: 'wss://ws.apiv2.cldqlf.bonduelle.com',
  localEmail: 'test@bonduelle.com',
  logLevel: 'DEBUG',
  cognitoDomain: 'https://ssoconnect.cldqlf.bnd-services.com',
  cognitoClientId: '4ui640sj1gqqf3fs64ft5mge6d',
}
