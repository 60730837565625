import { Technician } from '@agroone/entities'
import { Observable } from 'rxjs'
import { UserNetworkService } from '../user/user.network.service'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class TechniciansService {
  constructor(private userNetworkService: UserNetworkService) {}

  public getTechnicians(): Observable<Technician[]> {
    return this.userNetworkService.getTechnicians()
  }
}
