import { Injectable } from '@angular/core'
import { environment } from '@agropilot/env/environment'
import { NetworkingService } from '@agropilot/app/core/networking/services/networking.service'
import { Observable } from 'rxjs'
import { Croptype } from '@agroone/entities'

@Injectable({
  providedIn: 'root',
})
export class CroptypeNetworkService {
  constructor(private network: NetworkingService) {}

  get(): Observable<Croptype | Croptype[]> {
    return this.network.get(environment.newApiUrl + '/croptypes')
  }
}
