import { Injectable } from '@angular/core'
import { environment } from '@agropilot/env/environment'
import { NetworkingService } from '@agropilot/app/core/networking/services/networking.service'
import { Observable } from 'rxjs'
import { MsSqlPaginatedData, Region } from '@agroone/entities'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class RegionNetworkService {
  constructor(private network: NetworkingService) {}

  get(): Observable<Region | Region[]> {
    return this.network.get(environment.newApiUrl + '/regions')
  }
}
