import { ISO8601ToDate } from '@agroone/helpers'

import { Factory } from './factory'
import { FeatureCollection } from './feature-collection'
import { Location, Precision } from './location'
import { Offer } from './offer'
import { ReportingDAO } from './reporting'
import { SaveCrop } from './save-crop'
import { SaveFeatureCollection } from './save-feature-collection'
import { Scouting } from './scouting'

export class Planting {
  plantingId: string
  seedingRate: number
  seedingRateUnit: string
  germinationDate?: string
  weatherConditions?: string
  soilConditions?: string
  plantingNote?: string
  seedLines?: number
  beds?: number
  bedWidth?: number
  bedWidthUnit?: string
  plantingValidated?: boolean
  seedSpacing?: number
  seedSpacingUnit?: string
  initialPlannedHarvestDate?: string
  lotNumber?: string
  depthOfPlanting?: number
  depthOfPlantingUnits?: string
  fieldBuffer?: string
  rolledAfterSeed?: boolean
  specificationId?: number
  specificationName?: string
  sectorId?: number

  // test modification
  constructor() {
    this.plantingId = ''
    this.seedingRate = 0
    this.seedingRateUnit = ''
    this.germinationDate = ''
    this.weatherConditions = ''
    this.soilConditions = ''
    this.plantingNote = ''
    this.seedLines = 0
    this.beds = 0
    this.bedWidth = 0
    this.bedWidthUnit = ''
    this.plantingValidated = false
    this.seedSpacing = 0
    this.seedSpacingUnit = ''
    this.initialPlannedHarvestDate = ''
    this.lotNumber = ''
  }
}

export class Crop {
  static readonly className: string = 'Crop'

  id?: number
  plantedDate?: string
  plannedHarvestDate?: string
  plantedArea: number
  intendedArea: number
  areaUnit: string
  varietyId?: number
  varietyName?: string
  cropType: string
  farmName: string
  growerId: number
  growerName: string
  fieldName: string
  fieldId: number
  fieldArea: number
  fieldAreaUnit: string
  fieldOrganic: boolean
  startDate?: string
  campaign: string
  endDate?: string
  region: string
  regionId: number
  country: string
  location?: Location
  boundary?: FeatureCollection | SaveFeatureCollection
  boundaryId?: number
  planting?: Planting
  forecastHarvestDate?: string
  growingSystem?: string
  associatedCrop?: string
  notes?: string
  active?: boolean
  userId?: string
  updatedDate?: string
  technician?: string
  floweringScoutingId?: string
  yieldEstimationScoutingId?: string
  growthStageScoutingId?: number
  lastScoutingDate?: string
  plantNumber?: string
  externalId?: string
  externalClientId?: number
  destinationFactory?: Factory
  destinationFactoryId?: number
  reentryDate?: string
  campaignId: number
  cropTypeId?: number
  cropName?: string
  shortName?: string
  lastFloweringDate?: string
  // This property is filled only when the crops are included in the response body of the fields.
  foreignMaterialScoutings?: Scouting[]
  // This property is filled only when the query Parameter "includeOffers" is sent by the front-end
  offers: Offer[]
  lastTimeCropWasGrown: number
  productsUsedLastYear: number[]
  productsUsedTwoYearsAgo: number[]
  growsLastYear: CropGrowPreviousYear[]
  growsTwoYearsAgo: CropGrowPreviousYear[]
  problematicWeeds: string[]
  nutTrees: boolean
  isVirtual: boolean
  isFromVirtualCrop?: boolean
  virtualCropId?: number
  relatedCrops?: VirtualRelatedCrop[]

  constructor(crop?: Crop) {
    this.id = crop?.id
    if (crop?.cropName) {
      this.cropName = crop.cropName
    }
    if (crop?.shortName) {
      this.shortName = crop.shortName
    }
    // This property if filled only when the crops are included in the response body of the fields.

    this.foreignMaterialScoutings = crop?.foreignMaterialScoutings || []
    this.offers = crop?.offers || null
    this.plantedDate = crop?.plantedDate
    this.plannedHarvestDate = crop?.plannedHarvestDate
    this.plantedArea = crop?.plantedArea ?? 0
    this.intendedArea = crop?.intendedArea ?? 0
    this.areaUnit = crop?.areaUnit ?? ''
    this.varietyId = crop?.varietyId
    this.varietyName = crop?.varietyName
    this.farmName = crop?.farmName ?? ''
    this.growerId = crop?.growerId ?? 0
    this.growerName = crop?.growerName ?? ''
    this.fieldName = crop?.fieldName ?? ''
    this.fieldId = crop?.fieldId ?? null
    this.fieldArea = crop?.fieldArea ?? 0
    this.fieldAreaUnit = crop?.fieldAreaUnit ?? ''
    this.fieldOrganic = crop?.fieldOrganic ?? false
    this.startDate = crop?.startDate ?? null
    this.campaign = crop?.campaign ?? ''
    this.endDate = crop?.endDate
    this.growingSystem = crop?.growingSystem
    this.associatedCrop = crop?.associatedCrop
    this.cropType = crop?.cropType ?? ''
    this.cropTypeId = crop?.cropTypeId
    this.boundaryId = crop?.boundaryId ?? null
    this.region = crop?.region ?? ''
    this.regionId = crop?.regionId
    this.country = crop?.country ?? ''
    this.forecastHarvestDate = crop?.forecastHarvestDate
    this.notes = crop?.notes
    this.technician = crop?.technician ?? ''
    this.lastFloweringDate = crop?.lastFloweringDate
    this.active = !!crop?.active
    this.updatedDate = crop?.updatedDate
    this.userId = crop?.userId
    this.floweringScoutingId = crop?.floweringScoutingId
    this.yieldEstimationScoutingId = crop?.yieldEstimationScoutingId
    this.growthStageScoutingId = crop?.growthStageScoutingId
    this.lastScoutingDate = crop?.lastScoutingDate
    this.destinationFactory = crop?.destinationFactory ?? null
    this.destinationFactoryId = crop?.destinationFactoryId ?? null
    this.reentryDate = crop?.reentryDate ?? null
    this.campaignId = crop?.campaignId
    this.plantNumber = crop?.plantNumber
    this.externalId = crop?.externalId
    this.externalClientId = crop?.externalClientId
    this.lastTimeCropWasGrown = crop?.lastTimeCropWasGrown ?? null
    this.productsUsedLastYear = crop?.productsUsedLastYear ?? []
    this.productsUsedTwoYearsAgo = crop?.productsUsedTwoYearsAgo ?? []
    this.growsLastYear = crop?.growsLastYear ?? []
    this.growsTwoYearsAgo = crop?.growsTwoYearsAgo ?? []
    this.nutTrees = crop?.nutTrees ?? false
    this.isVirtual = false
    this.isFromVirtualCrop = crop?.isFromVirtualCrop ?? false
    this.virtualCropId = crop?.virtualCropId ?? null
    this.problematicWeeds = crop?.problematicWeeds ?? []
    this.relatedCrops = crop?.relatedCrops ?? []
    if (crop?.planting) {
      this.planting = crop.planting
    }
    if (crop?.location) {
      this.location = crop.location
    }
    if (crop?.boundary) {
      this.boundary = crop.boundary
    }
  }

  get plantedDateAsDate(): Date {
    return ISO8601ToDate(this.plantedDate)
  }

  get forecastHarvestDateAsDate(): Date {
    return ISO8601ToDate(this.forecastHarvestDate)
  }

  get reentryDateAsDate(): Date {
    return this.reentryDate ? ISO8601ToDate(this.reentryDate) : null
  }
}

export class CropGrowPreviousYear {
  constantKeyId: number
  area: number
  unit: string
}

export class CropDAO extends ReportingDAO {
  id?: number
  name: string | undefined
  shortName: string | undefined
  plantedDate?: string
  plantedArea: number | undefined
  intendedArea: number | undefined
  areaUnit: string | undefined
  startDate?: string
  endDate?: string
  plannedHarvestDate?: string
  technician?: string
  forecastHarvestDate?: string
  associatedCrop?: string
  growingSystem?: string
  notes?: string
  active?: number
  userId?: string
  plantNumber?: string
  externalId?: string
  externalClientId?: number
  problematicWeeds?: string
  reentryDate?: string
  floweringScoutingId?: number
  yieldEstimationScoutingId?: number
  lastScoutingDate?: string
  lastFloweringDate?: string
  nutTrees?: boolean
  cropTypeId: number | undefined
  plantingId?: number
  varietyId?: number
  fieldId: number | undefined
  boundaryId: number | undefined
  locationId: number | undefined
  factoryId?: number
  campaignId?: number
  growthStageScoutingId?: number
  lastTimeCropWasGrown?: number
  isVirtual?: boolean
  growerId?: number
  constructor(crop?: SaveCrop) {
    super(crop)
  }
}

export class CropPlantingDAO {
  id?: number
  plantingId?: string
  seedingRate?: number
  seedingRateUnit?: string
  germinationDate?: string
  weatherConditions?: string
  soilConditions?: string
  plantingNote?: string
  beds?: number
  seedLines?: number
  bedWidth?: number
  bedWidthUnit?: string
  plantingValidated?: number
  seedSpacing?: number
  seedSpacingUnit?: string
  initialPlannedHarvestDate?: string
  lotNumber?: string
  depthOfPlanting?: number
  depthOfPlantingUnits?: string
  fieldBuffer?: string
  rolledAfterSeed?: number
  specificationId?: number
  sectorId?: number
}

export class CropRequest extends CropDAO {
  // planting
  cPplantingId?: string
  cPseedingRate?: number
  cPseedingRateUnit?: string
  cPgerminationDate?: string
  cPweatherConditions?: string
  cPsoilConditions?: string
  cPplantingNote?: string
  cPbeds?: number
  cPseedLines?: number
  cPbedWidth?: number
  cPbedWidthUnit?: string
  cPplantingValidated?: number
  cPseedSpacing?: number
  cPseedSpacingUnit?: string
  cPinitialPlannedHarvestDate?: string
  cPlotNumber?: string
  cPdepthOfPlanting?: number
  cPdepthOfPlantingUnits?: string
  cPfieldBuffer?: string
  cProlledAfterSeed?: number
  cPspecificationId?: number
  cPsectorId?: number
  // join tables
  regionId: number | undefined
  region: string | undefined
  cropType: string | undefined
  country: string | undefined
  varietyName: string | undefined
  farmId: number | undefined
  farmName: string | undefined
  fieldArea: number | undefined
  fieldAreaUnit: string | undefined
  fieldOrganic: number | undefined
  campaign: string | undefined
  latitude: string | undefined
  longitude: string | undefined
  precision?: Precision
  geohash?: string
  boundary?: string
  factoryName: string | undefined
  factoryColor: string | undefined
  factoryQuality?: string
  factoryRegionId: number | undefined
  factoryCountryId: number | undefined
  factoryLocationId: number | undefined
  factoryLatitude: string | undefined
  factoryLongitude: string | undefined
  factoryPrecision?: Precision
  factoryGeohash?: string
  growerName?: string
  fieldName?: string
  cghIds?: string
  cghConstantKeyIds?: string
  cghAreas?: string
  cghUnits?: string
  cghYearAgo?: string
  cphIds?: string
  cphProductIds?: string
  cphYearAgo?: string
  relatedCropIds?: string
  relatedCropAreas?: string
  relatedCropAreaUnits?: string
  relatedCropNames?: string
  relatedCropActives?: string
  specificationName?: string
}

export class CropBoundary {
  id: number
  cropName: string
  boundary?: FeatureCollection

  constructor(cropBoundary?: CropBoundary) {
    this.id = cropBoundary?.id
    this.cropName = cropBoundary?.cropName ?? ''
    this.boundary = cropBoundary?.boundary ?? null
  }
}

export class VirtualRelatedCrop {
  id: number
  area: number
  areaUnit: string
  name: string
  active: boolean
}

/**
 * Specific class that is used to define if yes or no a crop is displayed at the bottom of the offer crop list and if it's greyed out
 */
export class AgropilotCrop extends Crop {
  isFullyHarvestedCrop: boolean
}
