import { TranslateService } from '@ngx-translate/core'

export function TranslateLocalIdFactory(translateService: TranslateService): string {
  // Agropilot use another code for Poland than i18n.
  const languageCodeMapping: { [key: string]: string } = {
    'po-po': 'pl',
  }
  const currentLang = translateService.currentLang
  return languageCodeMapping[currentLang] || currentLang
}
