import { CommonModule } from '@angular/common'
import { NgModule, Optional, SkipSelf } from '@angular/core'
import { ErrorModule } from './error/error.module'
import { NetworkingModule } from './networking/networking.module'
import { AppTranslateModule } from './translate/translate.module'

@NgModule({
  imports: [CommonModule, AppTranslateModule, NetworkingModule.forRoot(), ErrorModule.forRoot()],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only')
    }
  }
}
