import { EstimateTimeDetails } from './estimate-time-details'

export class EstimateTime {
  startWork: EstimateTimeDetails
  checkInSite: EstimateTimeDetails
  checkOutSite: EstimateTimeDetails
  checkInFactory: EstimateTimeDetails
  checkOutFactory: EstimateTimeDetails

  constructor() {
    this.startWork = new EstimateTimeDetails()
    this.checkInSite = new EstimateTimeDetails()
    this.checkOutSite = new EstimateTimeDetails()
    this.checkInFactory = new EstimateTimeDetails()
    this.checkOutFactory = new EstimateTimeDetails()
  }
}
