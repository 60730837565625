export class Stock {
  id: number
  name: string
  destination: string
  destination_color: string
  destination_id: number
  cropTypeId?: number
  regionId?: number
  cropType: string
  color: string
  quality: string
  actif: boolean
  min: number
  max: number
  region: string
  factoryId?: number

  constructor(stock?: Stock) {
    this.id = stock?.id ?? 0
    this.name = stock?.name ?? '-- ND --'
    this.destination = stock?.destination ?? ''
    this.destination_color = stock?.destination_color ?? '#EFD58D'
    this.destination_id = stock?.destination_id ?? null
    this.cropType = stock?.cropType ?? ''
    this.cropTypeId = stock?.cropTypeId ?? null
    this.regionId = stock?.regionId ?? null
    this.color = stock?.color ?? ''
    this.quality = stock?.quality ?? ''
    this.actif = stock?.actif ?? false
    this.max = stock?.max ?? null
    this.min = stock?.min ?? null
    this.region = stock?.region ?? null
    this.factoryId = stock?.factoryId ?? null
  }
}
export class StockConsumption {
  id?: number
  stockId: number
  date: string
  quantity: number
  cropType?: string
  destination?: string
  name?: string
  targetQuantity?: number

  constructor(consumption?: StockConsumption) {
    this.id = consumption?.id
    this.stockId = consumption?.stockId
    this.date = consumption?.date
    this.quantity = consumption?.quantity
    this.cropType = consumption?.cropType
    this.destination = consumption?.destination
    this.name = consumption?.name
    this.targetQuantity = consumption?.targetQuantity
  }
}

export class StockEntryDAO {
  public id: number
  public date: string
  public quantity: number
  public stockId: number
}

export class StockEntry {
  id: number
  cropType: string
  date: string
  destination: string
  name: string
  quantity: number
  stockId: number

  constructor(entry?: StockEntry) {
    this.id = entry?.id
    this.cropType = entry?.cropType
    this.date = entry?.date
    this.destination = entry?.destination
    this.name = entry?.name
    this.quantity = entry?.quantity
    this.stockId = entry?.stockId
  }
}

export class SaveStockEntry {
  date: string
  quantity: number
  stockId: number

  constructor(entry?: SaveStockEntry) {
    this.date = entry?.date
    this.quantity = entry?.quantity
    this.stockId = entry?.stockId
  }
}

export class StockDAO {
  id: number
  name: string
  cropTypeId: number
  color: string
  quality: string
  active: number
  min: number
  max: number
  factoryId: number
  regionId: number
}

export class StockProjection {
  color: string
  stock_id: number
  name: string
  cropType: string
  destination: string
  total: number
  hour: StockProjectionDetails[]
  quarter: StockProjectionDetails[]

  constructor(stockProjection: StockProjection) {
    this.color = stockProjection?.color ?? ''
    this.stock_id = stockProjection?.stock_id ?? null
    this.name = stockProjection?.name ?? ''
    this.cropType = stockProjection?.cropType ?? ''
    this.destination = stockProjection?.destination ?? ''
    this.total = stockProjection?.total ?? 0
    this.hour = stockProjection?.hour?.map((h) => new StockProjectionDetails(h)) ?? []
    this.quarter = stockProjection?.quarter?.map((q) => new StockProjectionDetails(q)) ?? []
  }
}

export class StockProjectionDetails {
  date: string
  entries: number
  provision: number
  consumption: number
  cumulate: number
  negative_cumulate: number
  min: number
  max: number

  constructor(stockProjectionDetails: StockProjectionDetails) {
    this.date = stockProjectionDetails?.date ?? null
    this.entries = stockProjectionDetails?.entries ?? null
    this.provision = stockProjectionDetails?.provision ?? null
    this.consumption = stockProjectionDetails?.consumption ?? null
    this.cumulate = stockProjectionDetails?.cumulate ?? null
    this.negative_cumulate = stockProjectionDetails?.negative_cumulate ?? null
    this.min = stockProjectionDetails?.min ?? null
    this.max = stockProjectionDetails?.max ?? null
  }
}

export interface StockProvision {
  stockId: number
  name: string
  cropType: string
  destination: string
  [key: string]: any
}
