import { Injectable } from '@angular/core'
import { UserPermissions } from '@agroone/entities'
import { ConnectionService, SharedPermissionService, SharedUserService } from '@agroone-front/shared'

@Injectable({
  providedIn: 'root',
})
export class PermissionService extends SharedPermissionService {
  constructor(
    protected override readonly userService: SharedUserService,
    protected override readonly connectionService: ConnectionService
  ) {
    super(userService, connectionService)
  }

  protected getOfflinePermissions(): UserPermissions[] {
    return []
  }
}
