export enum StatusOffer {
  /**
   * nv0: offer when the bypass property is true
   */
  offerDisable = 0,
  /**
   * nv1: offer just created;
   * available only in offer management screen but not yet in planification screen,
   * every action possible (edit, delete, cluster,bypass) on offer management screen
   */
  offerCreated = 1,
  /**
   * nv2: offer transfered to logistic;
   * the offer now appears in the upper part of the screen planification (1st agropilot screen),
   * every actions remain possible
   */
  offerPriorised = 2,
  /**
   * nv3: offer is attributed to a harvest team (and has harvest starting date and time, calculation for number of loads is done, etc…).
   * At this stage, any change in offer quantity will badly impact planning.
   * The change can be taken into account, but only with the agreement of logistics people
   * ( In previous version of agropilot, there is a colour sign that indicates a change in quantity;
   * logistic person has to remove from planning and plan again to enable the system to update number of loads and time line calculations;
   * as an improvement for users, should be replaced by an additional function=”update calculations Y/N”)
   */
  offerPlanified = 3,
  /**
   * nv4: harvest operation ongoing;
   * no change possible in offer management; real time information is replacing calculation;
   * logistic has the possibility to add or remove loads if necessary
   */
  offerStarted = 4,
  /**
   * nv5: offer close when all realtime is encoded
   */
  offerClose = 5,
}
