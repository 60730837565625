import { CommonModule } from '@angular/common'
import { Component, Input, TemplateRef } from '@angular/core'
import { MatToolbarModule } from '@angular/material/toolbar'

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
  standalone: true,
  imports: [MatToolbarModule, CommonModule],
})
export class HeaderBarComponent {
  @Input() headerTemplate: TemplateRef<any>
  @Input() actionsTemplate: TemplateRef<any>

  @Input() startColor: string
  @Input() endColor: string
}
