import { ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NetworkingService } from '@agropilot/app/core/networking/services/networking.service'

@NgModule({
  imports: [CommonModule],
  declarations: [],
})
export class NetworkingModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: NetworkingModule,
      providers: [NetworkingService],
    }
  }
}
