import { IsNumber, IsOptional } from 'class-validator'

export class CommentDAO {
  id?: number
  item: string
  subItem: string
  text: string
  dateBegin: string
  dateEnd: string
  color: string
  harvestTeamId: number
}

export class Comment {
  public id: number
  public harvestTeamId: number
  public item: string
  public subItem: string
  public text: string
  public dateBegin: string
  public dateEnd: string
  public color: string

  constructor(comment?: CommentDAO) {
    if (comment?.id) {
      this.id = comment?.id
    }
    this.harvestTeamId = comment?.harvestTeamId
    this.item = comment?.item
    this.subItem = comment?.subItem
    this.text = comment?.text
    this.dateBegin = comment?.dateBegin
    this.dateEnd = comment?.dateEnd
    this.color = comment?.color
  }
}
