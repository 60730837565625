import { AgroPilotEnvironment } from '@agroone/entities'

export const environment: AgroPilotEnvironment = {
  production: true,
  dev: false,
  qlf: false,
  int: false,
  local: false,
  dynatraceScriptSrc: 'https://js-cdn.dynatrace.com/jstag/1623d230d17/bf75358etl/1378e0a6127347fa_complete.js',
  newApiUrl: 'https://apiv2.cldprd.bonduelle.com',
  hubUrl: 'https://agroone.bonduelle.com',
  websocket: 'wss://ws.apiv2.cldprd.bonduelle.com',
  localEmail: 'test@bonduelle.com',
  logLevel: 'ERROR',
  serverLogLevel: 'WARN',
  mapboxDirectionsApi: 'https://api.mapbox.com/directions/v5/mapbox/',
  cognitoDomain: 'https://ssoconnect.bnd-services.com',
  cognitoClientId: '73cblp0ndib9adfof0tep26cbv',
  headerColorStart: '#ffd900',
  headerColorEnd: '#ffd900',
}
