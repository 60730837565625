import { StatusOffer } from '../enums/status-offer.enum'
import { Comment } from './comment'
import { ConstantLite } from './constant'
import { Sample } from './sample'
import { Scouting } from './scouting'
import { Offer } from './offer'

export abstract class BaseModel {}

export class HarvestTeam {
  public id: number
  public region: string
  public name: string
  public harvester: string
  public crop_types: string[] = [] //@deprecated  replaced with cropTypes
  public destination: string
  public destination_id: number //@deprecated replaced with factoryId
  public cadence: number
  public speed: number
  public active: boolean
  public previous_offer?: Offer
  public offers?: Offer[]
  public comments?: Comment[]
  public cropTypes?: {
    id: number
    name: string
  }[]
  public processType?: string
  public factoryId?: number
  public cadenceUnit?: string
  public speedUnit?: string
  public regionId?: number

  constructor(harvestTeam?: HarvestTeam) {
    this.id = harvestTeam?.id ?? 0
    this.region = harvestTeam?.region ?? null
    this.name = harvestTeam?.name ?? null
    this.harvester = harvestTeam?.harvester ?? null
    this.cropTypes = harvestTeam?.cropTypes ?? []
    this.processType = harvestTeam?.processType ?? null
    this.factoryId = harvestTeam?.factoryId ?? null
    this.destination_id = harvestTeam?.destination_id ?? null
    this.destination = harvestTeam?.destination ?? null
    this.cadence = harvestTeam?.cadence ?? null
    this.cadenceUnit = harvestTeam?.cadenceUnit ?? null
    this.speed = harvestTeam?.speed ?? null
    this.speedUnit = harvestTeam?.speedUnit ?? null
    this.regionId = harvestTeam?.regionId ?? null
    this.active = harvestTeam?.active ?? false
    this.offers = [new Offer()]
    this.comments = [new Comment()]
    this.previous_offer = new Offer()
  }
}

export class SaveHarvestTeam {
  public id: number
  public region: string
  public name: string
  public harvester: string
  public crop_types: string[] = []
  public processType: string
  public destination: string
  public cadence: number
  public cadenceUnit: string
  public speed: number
  public speedUnit: string
  public active: boolean

  constructor() {
    this.id = 0
    this.region = ''
    this.name = ''
    this.harvester = ''
    this.crop_types = ['']
    this.processType = ''
    this.destination = ''
    this.cadence = 0
    this.cadenceUnit = ''
    this.speed = 0
    this.speedUnit = ''
    this.active = false
  }
}

export class SaveHarvestOffer {
  id?: number
  cropId: number
  readyToHarvest: number
  priority: number
  typeOfCulture?: string
  quality?: string
  risk?: string
  phyto?: string
  phytoFilterDate?: string
  observation?: string
  forecastHarvestDate?: string
  areaToHarvest?: number
  areaUnit?: string
  yield?: number
  quantityToHarvest?: number
  quantityUnit?: string
  bypass: number
  demandSampleDate?: string
  status?: number
  groupCode?: string
  yieldUnit?: string
  confirmedHarvestDate?: string
  lastCheckOutSiteDate?: string
  endDate?: string
  durationLots?: number

  constructor(offer?: Offer) {
    this.id = offer?.id
    this.cropId = offer?.cropId
    this.readyToHarvest = offer?.readyToHarvest ? 1 : 0
    this.priority = offer?.priority
    this.typeOfCulture = offer?.typeOfCulture
    this.quality = offer?.quality
    this.phyto = offer?.phyto
    this.phytoFilterDate = offer?.phytoFilterDate
    this.observation = offer.observation
    this.forecastHarvestDate = offer?.forecastHarvestDate
    this.areaToHarvest = offer?.areaToHarvest
    this.areaUnit = offer?.areaUnit
    this.yield = offer?.yield
    this.quantityToHarvest = offer?.quantityToHarvest
    this.quantityUnit = offer?.quantityUnit
    this.bypass = offer?.bypass ? 1 : 0
    this.status = offer?.status >= 0 ? offer.status : StatusOffer.offerCreated
    this.groupCode = offer?.groupCode ?? 'default'
    this.yieldUnit = offer?.yieldUnit
    this.confirmedHarvestDate = offer?.confirmedHarvestDate
    this.lastCheckOutSiteDate = offer?.lastCheckOutSiteDate
    this.endDate = offer?.endDate
    this.durationLots = offer?.durationLots
  }
}

export class HarvestOfferPriorisation extends Offer {
  public declare samples: Sample[]
  public lastScouting: Scouting
}

export class HarvestGroupementKey {
  code: string
  cropTypes: any[]
  regions?: string[]
  translation: string
  type: string

  constructor(constantLite: ConstantLite) {
    this.code = constantLite.code
    this.cropTypes = constantLite.cropTypes
    this.regions = constantLite?.regions?.map((v) => v.name) ?? []
    this.translation = constantLite.translation
    this.type = constantLite.type
  }
}

export class HarvestGroupementType {
  code: string
  translation: string

  constructor(harvestGroupementType: ConstantLite) {
    this.code = harvestGroupementType.code
    this.translation = harvestGroupementType.translation
  }
}

export class HarvestDirection {
  id?: number
  location_latitude_a: string
  location_longitude_a: string
  location_latitude_b: string
  location_longitude_b: string
  distance: number
  motorway: boolean
  region: string

  constructor(distance: HarvestDirection) {
    this.id = distance.id ? distance.id : null
    this.location_latitude_a = distance.location_latitude_a ? distance.location_latitude_a : null
    this.location_longitude_a = distance.location_longitude_a ? distance.location_longitude_a : null
    this.location_latitude_b = distance.location_latitude_b ? distance.location_latitude_b : null
    this.location_longitude_b = distance.location_longitude_b ? distance.location_longitude_b : null
    this.distance = distance.distance ? distance.distance : 0.0
    this.motorway = distance.motorway ? distance.motorway : false
    this.region = distance.region ? distance.region : null
  }
}

export class HarvestTeamDAO {
  id?: number
  name: string
  harvester: string
  processType: string
  cadence: number
  cadenceUnit: string
  speed: number
  speedUnit: string
  active: number
  factoryId: number
  regionId: number

  constructor(harvestTeam: HarvestTeamDAO) {
    if (harvestTeam?.id) {
      this.id = harvestTeam?.id ?? null
    }
    this.name = harvestTeam?.name ?? null
    this.harvester = harvestTeam?.harvester ?? null
    this.processType = harvestTeam?.processType ?? null
    this.cadence = harvestTeam?.cadence ?? null
    this.cadenceUnit = harvestTeam?.cadenceUnit ?? null
    this.speed = harvestTeam?.speed ?? null
    this.speedUnit = harvestTeam?.speedUnit ?? null
    this.active = harvestTeam?.active ?? null
    this.factoryId = harvestTeam?.factoryId ?? null
    this.regionId = harvestTeam?.regionId ?? null
  }
}

export class HarvestTeamCropDAO {
  id?: number
  harvestTeamId: number
  cropTypeId: number

  constructor(harvestTeamCrop: HarvestTeamCropDAO) {
    if (harvestTeamCrop?.id) {
      this.id = harvestTeamCrop?.id ?? null
    }
    this.cropTypeId = harvestTeamCrop?.cropTypeId ?? null
    this.harvestTeamId = harvestTeamCrop?.harvestTeamId ?? null
  }
}

export class HarvestTeamPlanification extends HarvestTeam {
  declare offers: Offer[]
  declare comments: Comment[]

  constructor(harvestTeamPlanification: HarvestTeamPlanification) {
    super(harvestTeamPlanification)
    this.offers = harvestTeamPlanification.offers ?? []
    this.comments = harvestTeamPlanification.comments ?? []
  }
}
