import { plainToInstance, Type } from 'class-transformer'
import {
  IsBoolean,
  IsDateString,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  Length,
  Validate,
  ValidateNested,
} from 'class-validator'

import { SaveReporting } from './save-reporting'
import { IsISO8601WithTimezone } from '@agroone/helpers'

export class SaveOffer extends SaveReporting {
  public static override build(raw: any) {
    return plainToInstance(SaveOffer, raw)
  }

  @IsInt()
  @IsPositive()
  @IsOptional()
  id?: number

  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  cropId!: number

  @IsString()
  @IsNotEmpty()
  region!: string

  @IsBoolean()
  @IsNotEmpty()
  readyToHarvest!: boolean

  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  priority!: number

  @IsString()
  @IsOptional()
  typeOfCulture?: string

  @IsString()
  @IsOptional()
  quality?: string

  @IsString()
  @IsOptional()
  phyto?: string

  @IsString()
  @IsOptional()
  phytoFilterDate?: string

  @IsString()
  @IsOptional()
  observation?: string

  @IsOptional()
  @Length(10)
  @IsDateString()
  forecastHarvestDate?: string

  @IsNumber()
  @IsNotEmpty()
  areaToHarvest: number

  @IsString()
  @IsNotEmpty()
  areaUnit: string

  @IsNumber()
  @IsNotEmpty()
  yield: number

  @IsString()
  @IsNotEmpty()
  yieldUnit?: string

  @IsNumber()
  @IsOptional()
  quantityToHarvest?: number

  @IsString()
  @IsOptional()
  quantityUnit?: string

  @IsBoolean()
  @IsNotEmpty()
  bypass: boolean

  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  status: number

  @IsString()
  @IsOptional()
  groupCode?: string

  @Length(10)
  @IsDateString()
  @IsOptional()
  demandSampleDate?: string

  @Length(10)
  @IsDateString()
  @IsOptional()
  confirmedHarvestDate?: string

  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  lastCheckOutSiteDate?: string

  @Validate(IsISO8601WithTimezone)
  @IsOptional()
  endDate?: string

  @IsNumber()
  @IsOptional()
  durationLots?: number

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveOfferRisk)
  observationRisks?: SaveOfferRisk[]
}

export class SaveOfferRisk {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  @IsOptional()
  scoutingId?: number

  @IsString()
  @IsNotEmpty()
  scoutingSubType?: string

  @IsString()
  @IsNotEmpty()
  name?: string
}
