interface ISyncPlafication {
  sector: boolean
  harvest_team: boolean
}

interface ISyncPcRadio {
  harvest_team: boolean
}

interface ISyncTruck {
  harvest_team: boolean
}

export class SyncModel {
  planification: ISyncPlafication
  pc_radio: ISyncPcRadio
  truck: ISyncTruck

  constructor(sector?: boolean, harvest_team?: boolean) {
    this.planification = {
      sector: sector ? sector : false,
      harvest_team: harvest_team ? harvest_team : false,
    }
    this.pc_radio = {
      harvest_team: harvest_team ? harvest_team : false,
    }
    this.truck = {
      harvest_team: harvest_team ? harvest_team : false,
    }
  }
}
