import { plainToInstance } from 'class-transformer'

export interface MsSqlNotEqualityFilter<T> {
  operator: 'notequality'
  fieldName: string
  value: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlEqualityFilter<T> {
  operator: 'equality'
  fieldName: string
  value: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlSuperiorityFilter<T> {
  operator: 'superiority'
  fieldName: string
  isStrict: boolean
  value: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlInferiorityFilter<T> {
  operator: 'inferiority'
  fieldName: string
  isStrict: boolean
  value: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlLikeFilter<T> {
  operator: 'like'
  fieldName: string
  value: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlStartsWithFilter<T> {
  operator: 'startswith'
  fieldName: string
  value: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlEndsWithFilter<T> {
  operator: 'endswith'
  fieldName: string
  value: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlRangeFilter<T> {
  operator: 'range'
  fieldName: string
  min: T
  max: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlOrFilter<T> {
  operator: 'or'
  fieldName: string
  value: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlExistsFilter {
  operator: 'exists'
  fieldName: string
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlNotExistsFilter {
  operator: 'notexists'
  fieldName: string
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlValuesFilter<T> {
  operator: 'values'
  fieldName: string
  values: T[]
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export type MsSqlFilter<T = string | number | boolean> =
  | MsSqlEqualityFilter<T>
  | MsSqlNotEqualityFilter<T>
  | MsSqlSuperiorityFilter<T>
  | MsSqlInferiorityFilter<T>
  | MsSqlLikeFilter<T>
  | MsSqlStartsWithFilter<T>
  | MsSqlEndsWithFilter<T>
  | MsSqlRangeFilter<T>
  | MsSqlOrFilter<T>
  | MsSqlValuesFilter<T>
  | MsSqlExistsFilter
  | MsSqlNotExistsFilter

export class MsSqlFieldSelected {
  required: string[] | undefined
  removed: string[] | undefined
}

export class MsSqlQueryOptionsPagination {
  static firstResult: MsSqlQueryOptionsPagination = MsSqlQueryOptionsPagination.build({ page: 1, length: 1 })

  static build(raw: any, defaultPageSize: number = 20) {
    const result: MsSqlQueryOptionsPagination = plainToInstance(MsSqlQueryOptionsPagination, raw)
    if (!result.page) {
      result.page = 1
    }
    if (!result.length) {
      result.length = defaultPageSize
    }
    return result
  }

  length: number | undefined
  page: number | undefined
}

export interface MsSqlQueryOptions {
  filters?: MsSqlFilter[]
  fields?: MsSqlFieldSelected
  sort?: { [key: string]: 'asc' | 'desc' }
  pagination?: MsSqlQueryOptionsPagination
}

export interface MsSqlQueryJoinClause {
  tableName: string
  columnName: string
  fkColumnName: string
  alias: string
}
