<mat-toolbar
  class="header"
  [ngStyle]="{ 'background-image': 'linear-gradient(0deg, ' + endColor + ' 0%, ' + startColor + ' 100%)' }"
>
  <!-- Header left -->
  <div class="header-title">
    <ng-container *ngTemplateOutlet="headerTemplate" />
  </div>

  <div class="header-actions">
    <ng-container *ngTemplateOutlet="actionsTemplate" />
  </div>
</mat-toolbar>
